function fieldTypeToGraphInputType(fieldType) {
    switch(fieldType) {
        case "Numeric":
        case "Range":
            return "Decimal";
        case "Rating":
            return "RatingInputType";
        case "Boolean":
            return "Boolean";
        default:
            return "String";
    }
}

function mapFieldToArg(field) {
    return `$${field.name}: ${fieldTypeToGraphInputType(field.type)}`;
}

function mapFieldContent(field) {
    return `${field.name}: $${field.name}`;
}

export function buildCreateReviewMutation(customFields = []) {
    const parts = [];
    parts.push(`mutation createReview(
            $itemId: String!
            $authorId: String
            $name: String
            $email: String
            $languageHint: String
            $transactionId: String
            $transactionSecret: String
            $privateAuthor: Boolean`);
    parts.push(...customFields.map(mapFieldToArg));
    parts.push(`) {
                createReview(
                    itemId: $itemId
                    authorId: $authorId
                    languageHint: $languageHint
                    name: $name
                    email: $email
                    transactionId: $transactionId
                    transactionSecret: $transactionSecret
                    privateAuthor: $privateAuthor`);
    parts.push(...customFields.map(mapFieldContent));
    parts.push(`) {
                    id
                    secret
                }
            }`);

    return parts.join('\n');
}